import React from "react";

import Slider from "./Slider";
import Analytics from "./Analytics";
import ClientSlider from "./ClientSlider";
import Download from './Download';
import Tabs from "./Tabs";

function Home() {
  return (
    <>
    
      <Slider />
      <Tabs/>
      <ClientSlider/>
      <Download/>
      <Analytics/>

      
    </>
  );
}

export default Home;
