import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
function About() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About |Alpha Refrigeration Company</title>
        <link rel="canonical" href="https://www.alpharefrigerationcompnay.co.in/about" />
      </Helmet>
      <Container>
        <Row>
          <Col className="content">
            <p>Alpha Refrigeration Company is a leading company established in 2014, in cold storages, by Mr. Ayyub Bagwan, to form a diversified group of H.R Cooling Services. The company is an association of young, dynamic an dedicated industry professionals, who are
              experts in their feild. Professionals in the team have hands on operational excellence and are abreast with the latest technologies in the industry.

            </p>

            <p>
              We provide premium cold storages equipments in line with industry standards. We are also a manufacturer of premium quality indoor cooling units, water coolers, cable trays, C - channel and AC stands.

            </p>

            <p>
              We bring a truly international range of commercial cooling and food service products solutions for bakeries,
              hotels, restaurants, bars, pubs, ice cream and beverages, food retail display, food preservation, healthcare segments.

            </p>
            <p>
              Our experience provides seamless integration to ensure proper fitment at site and offer complete turnkey solutions for

            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <h1 className="title">Cold Storages</h1>
            <ul>
              <li>Fruits and Vegetables</li>
              <li>Dairy Products</li>
              <li>Hospitality Industry</li>
              <li>Pharma Industry</li>

            </ul>
          </Col>
        
          <Col lg={6}>
            <h1 className="title">Other Products</h1>
            <ul>
              <li>Indoor Cooling Units for Cold Storages</li>
              <li>Cable Trays</li>
              <li>Deep Freezers</li>
              <li>C- Channels</li>
              <li>AC Stands</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <p>At Alpha refrigeration system we focus on manufacturing of heavy duty and premium quality products. We have fully equipped machines
            and enthusiastic team of technicians who get premium quality job done in given timeframe. Alpha refrigeration was born into a manufacturing set
            up through the able guidance of the managment team specialised into refrigeration and cold rooms.
          </p>
          <p>We have 700+ clients today who actively use our product all over in India. Their trust and support highly motivate us to enhance our product and services.</p>
        </Row>
      </Container>
    </>
  );
}

export default About;
