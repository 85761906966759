import React from 'react';
import { Col, Row, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
// import p1 from '../assets/portfolio/p1.webp'

function Team() {
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Team | Alpha Refrigeration Company</title>
      <link rel="canonical" href="https://www.alpharefrigerationcompnay.co.in/team" />
    </Helmet>

    <Container >

      <Row>
        <Col lg={12}>
          <div className="team">

            <h5>AYYUB BAGWAN<br /><span className="positions">(Founder)</span></h5>

          </div>
          <div>

            <p>Mr. Ayyub is the founder of Alpha Refrigeration, with 20 years plus experience in the refrigeration and cold storage industry. With
              manufacturing expertise and hands on service experience he has excellent technical knowledge of the subject. Mr. Ayyub has been awarded the Udyog Ratna Award by Govt of Maharashtra due to his performance in the industry sector.
            </p>

          </div>
        </Col>
        </Row>
        <Row>
        <Col lg={12}>
          <div className="team">
            <p><i>The manufacturing businesses have grown rapidly expanding its portfolio to become one of the popular name in market. With an unwavering focus on delivering quality, Alpha refrigeration have won the trust of leading OEM and aftermarket
              customers.</i></p>
          </div>
        </Col>

      </Row>

    </Container>


  </>;
}

export default Team;
