import React from 'react'
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";


function Footer() {
    let yr = new Date();

    return (
        <>
            <Container fluid id="footer">
                <Row>
                    <Col >

                        <ul>
                            <li><Link to="products/cold-rooms" className="footer-item">Cold Rooms</Link> </li><span className="separator"> | </span>
                            <li><Link to="products/evaporator-indoor-unit" className="footer-item">Evaporator Indoor Unit</Link> </li><span className="separator"> | </span>
                            <li><Link to="products/display-refrigeration" className="footer-item">Display Refrigeration</Link> </li><span className="separator"> | </span>
                            <li><Link to="products/cable-trays" className="footer-item">Cable Tray</Link> </li><span className="separator"> | </span>
                            <li><Link to="products/c-channel_and_z-channel" className="footer-item">Z-Channels </Link> </li><span className="separator"> | </span>
                            <li><Link to="products/c-channel_and_z-channel" className="footer-item">C-Channels</Link> </li><span className="separator"> | </span>
                            <li><Link to="products/ac-stand" className="footer-item">AC Stand</Link> </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <p className="copyright" >Copyright &copy; {yr.getFullYear()}. Alpha Refrigeration Company </p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Footer
