import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "../assets/logo.webp";
import { Link } from "react-router-dom";


function Navigation() {
  return (
    <>
     

      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand ><Link to="/" className="nav-link logo-area">
          <img src={logo} id="logo" alt="logo"  draggable="false"/> </Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link >
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </Nav.Link>
              <NavDropdown title="About">
                <NavDropdown.Item href="#">
                  <Link to="about" className="dropdown-item">
                    Alpha Refrigeration Company
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <Link to="team" className="dropdown-item">
                    Our Team
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Products">
                <NavDropdown.Item href="#">
                  <Link to="products/cold-rooms" className="dropdown-item">
                    Cold Rooms
                  </Link>
                </NavDropdown.Item>
               
                <NavDropdown.Item href="#">
                  <Link
                    to="products/evaporator-indoor-unit"
                    className="dropdown-item"
                  >
                   Evaporator Indoor unit
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <Link to="products/display-refrigeration" className="dropdown-item">
                    Display Refrigeration
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <Link to="products/cable-trays" className="dropdown-item">
                    Cable Trays
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <Link to="products/ac-stand" className="dropdown-item">
                    AC Stand
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="#">
                  <Link
                    to="products/c-channel_and_z-channel"
                    className="dropdown-item"
                  >
                    C-Channel and Z-Channel
                  </Link>
                </NavDropdown.Item>
                
                
              </NavDropdown>

              <Nav.Link >
                <Link to="/contact-us" className="nav-link">
                  Contact us
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;
