import logo from './logo.svg';
//import logo from './logo.svg'`;
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import $ from 'jquery';

import Header from './components/Header';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Products from './components/Products';
import Error from './components/Error';
import Contact from './components/Contact';
import Team from './components/Team';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {
  $(document).ready(function(){
    // $('img').attr('draggable', false);
    // $('body').attr('draggable', false);

    $('#form-submit').unbind().click(function(){
      let name=$('#name').val();
      let email=$('#email').val();
      let subject=$('#subject').val();
      let contactno=$('#contactno').val();
      let message=$('#message').val();
      let str=`mailto:sales@alpharefrigeration.in?subject=${subject}&body=Hello, ${message}. \n Regards, ${name} \n ${contactno}\n ${email}`;
     
      window.location.href = str;
    });

  });
  $(document).bind("contextmenu",function(e){
    return false;
      });
  return (
    <>
    
    <BrowserRouter>
    <Header />
      <Navigation />

    <Routes>
      <Route path="/" element={<Home />}/>
        <Route index path="/home" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/contact-us" element={<Contact />}/>
        <Route path="/team" element={<Team />}/>
        <Route path="/products/:page" element={<Products />}/>
        
        <Route path="*" element={<Error />}/>
   </Routes>
   <Footer/>
  </BrowserRouter>

  
   </>
  );
}

export default App;
