import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Slide0 from "../assets/cold.webp";

import Slide2 from "../assets/02.webp";
import Slide3 from "../assets/03.webp";
import Slide4 from "../assets/04.webp";
import Slide00 from "../assets/00.webp";
import cru from '../assets/cru.webp';

function Slider() {
  return (
    <>
      <Carousel>
      <Carousel.Item>
          <img className="d-block w-100" src={Slide00} alt="AC STAND" draggable="false" />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
      <Carousel.Item>
          <img className="d-block w-100" src={Slide0} alt="cold storage" draggable="false" />
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={cru} alt="cold storage unit"  draggable="false"/>
          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        
        <Carousel.Item>
          <img className="d-block w-100" src={Slide2} alt="f2"  draggable="false"/>

          <Carousel.Caption></Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slide3} alt="f3" draggable="false"/>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slide4} alt="f4" draggable="false"/>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Slider;
