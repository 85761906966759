import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

import cs from '../assets/Alpha-refrigeration-cold-storage-catalogue.pdf';
function Download() {
  return <>
  <Container>
      <Row>
          <Col sm={12} lg={6}>
          <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/1ar8nJ5Iz_c?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </Col>
     
        <Col>
          <h3>Brochure</h3>
          
          <a href={cs} download className='download-button'><i class="fas fa-download"></i> Cold Rooms Brochure  </a> 
          
          </Col>
      </Row>
  </Container>
    
  </>;
}

export default Download;
