import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import {Helmet} from "react-helmet";
function Contact() {
 
  return (
    <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact with Alpha Refrigeration Company</title>
                <link rel="canonical" href="https://www.alpharefrigerationcompnay.co.in/contact-us" />
            </Helmet>
      <Container>
        <Row>

          <Col className="content">
            <h1>Contact us</h1>
          </Col>
        </Row>
        <Row>
            <Col lg={12} >
            <div>
           
            <div className="base-contact">
             
              <i className="lni-map-marker icons cyan-color contact-info-icon"></i>
              <h1 className="Companytitle">Alpha Refrigeration Company</h1>
            
              <p>Office No.2, Shub Residency 85/1, Mangalwar Peth, Parage Chowk, Pune - 411 011</p>
             
              <p>
              <i className="fa fa-mobile icons cyan-color contact-info-icon"></i>
              <a href="tel:+919822677479" className="cntd">&nbsp;&nbsp;(+91) 9822677479</a>/&nbsp;&nbsp;
              <i className="fa fa-mobile icons cyan-color contact-info-icon"></i>
              
              <a href="tel:+918767022970" className="cntd">&nbsp;&nbsp;(+91) 8767022970</a> 
             
              </p>
              
              
            
            <div>
            <a href="mailto:sales@alpharefrigeration.in" className="cntd"><i className="fa fa-envelope icons cyan-color contact-info-icon"></i> sales@alpharefrigeration.in</a>

            <div>
            <br/>
            <a href="mailto:sales@alpharefrigeration.in" className="btn inqbtn"><i className="fa fa-envelope icons cyan-color contact-info-icon"></i> Get in touch with us</a>
              <br/> <br/> <br/>
            </div>
         
               
            </div>
            </div>
            
          </div>
            </Col>
           
           
        </Row>
        
      </Container>
    </>
  );
}

export default Contact;
