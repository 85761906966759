import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import {  useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Productspecification from "./Productspecification";
import coldroom from "../assets/portfolio/v-cold-storage.webp";
import sideimg from "../assets/portfolio/side.webp";
import cabletray from "../assets/portfolio/cable-tray.webp";
import czchannel from "../assets/portfolio/candz.webp";

import acstand from "../assets/portfolio/ac-bracket.webp";
import Slide3 from "../assets/03.webp";
import ac1 from "../assets/portfolio/assembly.webp";
import ac2 from "../assets/portfolio/assembly2.webp";
import ac3 from "../assets/acstand1.webp";
import ac4 from "../assets/acstand2.webp";

import indoor from "../assets/portfolio/indoor.webp";
import prf1 from "../assets/portfolio/prf1.webp";
import prf2 from "../assets/portfolio/prf2.webp";
function Products() {
  let { page } = useParams();

  const product = [
    {
      tags: "cold-rooms",
      name: "Cold Rooms",
      image: coldroom,
      specification:
        "Being pioneers in the cold storage industry we have expertise in design, supply, installation an for your cold rooms. Our specialize in preservation & storage of products ranging from Hotels, Hospitality, Healthcare frozen foods, fresh produce dairy products, beverages. We provide complete services from concept to commissioning of walk in cold rooms, refrigerated warehouses.",
      product_categories: (
        <Table responsive>
          <thead>
            <tr>
              <th>Cold Rooms</th>
            </tr>
          </thead>
          <tbody>
           
            <tr>
              <td>WALK IN COLD ROOM</td>
            </tr>
            <tr>
              <td>REFRIGERATED WAREHOUSES</td>
            </tr>
            <tr>
              <td>RIPENING CHAMBERS</td>
            </tr>
            <tr>
              <td>FRUIT AND VEG COLD STORAGES</td>
            </tr>
            <tr>
              <td>DAIRY COLD STORAGE</td>
            </tr>
            <tr>
              <td>BLAST CHILLER & FREEZERS</td>
            </tr>
          </tbody>
        </Table>
      ),
      other: (
        <>
          <ul>
            <li><h5>Professional Refrigeration</h5>
          <p>
            We offer complete range of premium branded stainless steel
            refrigerated Chillers and Freezers, Blast Chiller and Freezers, for
            industrial canteens, flight kitchens, restaurants, hotels, cloud
            kitchens and quick service restaurants.Our products are from reputed
            and international manufacturers, built using adanced techlology, and
            energy efficiency.
          </p>
          <Container>
            <Row>
            <Col  lg={6}>
            <img src={prf2} alt="prf2" className="product-prof"/>
           </Col>
           <Col lg={6}>
            <img src={prf1} alt="prf1" className="product-prof"/>
           </Col>
            </Row>
            <Row>
              <Col lg={6}>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/BosNQsh3o6M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>
              <Col lg={6}>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/JNdamUj6NgA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>
            </Row>
          </Container>
          
          
          </li>
          </ul>
          
         
        </>
      ),
    },
    {
      tags: "commercial-kitchen-equipments",
      name: "Commercial Kitchen Equipments",
      image: sideimg,
      specification:
        "",
      product_categories: <>
        <ul>
            <li>
                <h5>SS fabricated custom equipments</h5>
                <p>We offer premium quality customised stainless steel equipments and our experience ensures correct fitment at site.
          Due to right planning from concept to commissioning, our projects are state of art and timely deliveries. We cater to various
  	  industries such as <b>staff canteens, hotels, restaurants, clubs, resorts, educational institutes, hospitals, catering segments.</b></p>
            </li>
            <li>
                <h5>Professional Refrigeration</h5>
                <p>We offer complete range of <b>premium branded stainless steel refrigerated chillers and freezers, blast chiller and
        freezers, for industrial canteens, flight kitchens, restaurants, hotels, cloud kitchens and quick service restaurants. </b>
	 Our products are from reputed and international manufacturers, built using adanced techlology, and energy efficiency.</p>
            </li>
            <li>
                <h5>Bakery Equipments</h5>
                <p>We offer premium range of bakery equipments, such as <b>rotary ovens, deck ovens, dough mixers, planetary mixers, bread
	slicers, cake counter and display refrigeration.</b></p>
            </li>
            <li>
                <h5>Food Preparation Equipments</h5>
                <p>We offer premium range of food preparation equipments such as <b>vegetable slicers, food processors, cutter and vegetable slicers,
	planetary mixers, potato peelers, multipurpose washers and dryers, vacumm packers and other equipments </b>.

	
	We offer equipments from premium brands from reputed manufacturers from India and abroad namely Electrolux, Dito Sama, Rational, Berjaya, S
        Salva, Sirman, Cambro and other premium brands.
</p>
            </li>
            <li>
                <h5>Combi Ovens</h5>
                <p>We have a wide range combi oven range. Make your Kitchen more efficient, producitve and stree free with our range of combi ovens, coming in various sizes 
	from 6 GN 1/1, 10 GN 1/1, 20 GN 1/1 and 40 GN 1/1 to cater to all your needs.
	</p>
            </li>
        </ul>
      </>,
      other: "",
    },
    {
      tags: "cable-trays",
      name: "Cable Trays",
      image: cabletray,
      specification:
        "We are widely known for designing Hot Dip Galvanized Perforated Cable Trays that are highly durable and the holes provide necessary ventilation. These are suited for heavy cables that demand extra protection from physical damages. These are available in various standard dimensions and lengths as well as offered as per the specification of our clients.",
      product_categories: <>
        <ul>
        <li>
                <h5>GI Cable Tray</h5>
                
            </li>
            <li>
                <h5>Hot Dip Cable Tray</h5>
             
            </li>
            <li>
                <h5>Powder Coated Cable Tray</h5>
            </li>
            <li>
                <h5>Threaded Rods</h5>
            </li>
        </ul>
      </>,
      other: "",
    },
    {
      tags: "c-channel_and_z-channel",
      name: "C-Channel & Z-Channel",
      image: czchannel,
      specification:
        "We provide a wide variety of C- Channels and Z- Channels according to the needs of our customers around the globe. The trust of our thousands of customers with us motivates us to manufacture high quality, durable C-Channels and Z-Channels.",
      product_categories: <>
        <ul>
            <li>
                <h5>C-Channel</h5>
                
            </li>
            <li>
                <h5>Z-Channel</h5>
                
            </li>
           
        </ul>
      </>,
      other: "",
    },
    {
      tags: "ac-stand",
      name: "AC-Stand",
      image: acstand,
      specification:
        "",
      product_categories: <>
        <ul>
            <li>
                <h5>AC STAND</h5>
                <p>We are a pioneer in manufacturing high quality AC stands. The AC stand is our high selling product and has the trust of a number of clients around India.
</p>



            </li>
            <li>
              
              <img src={ac3} alt="ac1" width="250px" style={{"padding":"2px"}}/>&nbsp;
<img src={ac4} alt="ac4" width="250px" style={{"padding":"2px"}}/></li>
        <li>
          <table id="tab">
            <tr>
              <td>Weight Load: Upto 0.8 ton to 3 ton</td>
              
              
            </tr>
            <tr><td>Available in 3 types depending on load capacity</td></tr>
        <tr><td>Off-white color, Powder Coated, GI/ GP Material</td></tr>
        <tr><td>Packaging cotains assembly and fasteners. The unique design of the leg extender allows you to freely adjust the length of the support arm ( from 8 1/2” to 13 3/4” ) , which well adapted to the different outside wall, relieved weight stress on window frame</td></tr>
          </table>
       
        </li>
           <li>
           <img src={ac1} alt="ac1" width="200px"/> &nbsp;
<img src={ac2} alt="ac2" width="200px"/>
           </li>
        </ul>
      </>,
      other: "",
    },
    {
      tags: "display-refrigeration",
      name: "Display Refrigeration",
      image: Slide3,
      specification:
        "We have supplied and installed various kinds of supermarket display refrigeration, with state of art Equipments using latest technologies. Connect with us for your supermarket refrigeration requirements",
      product_categories: <>
        
      </>,
      other: "",
    },
    {
      tags: "evaporator-indoor-unit",
      name: "Evaporator Indoor Unit",
      image:indoor,
      specification:
        "Alpha refrigeration company manufacture's heavy duty evaporator indoor units.",
      product_categories: <>
        <ul>
           
            <li>
                <h5>Evaporator Indoor Unit</h5>
                <p>As per requirement of solutions we manufacture variety of sizes evapoator indoor units.</p>
            </li>
           
           
        </ul>
      </>,
      other: "",
    },
  ];
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="content" >
            {product.map((prod) => {
              if (prod.tags == page)
                return (
                  <Productspecification
                    name={prod.name}
                    imagepath={prod.image}
                    specification={prod.specification}
                    product={prod.product_categories}
                    extra={prod.other}
                  />
                );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Products;
