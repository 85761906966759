import React from 'react';
//import $ from "jquery";
import { Container, Row, Col } from 'react-bootstrap';
import slidemenu from '../assets/slide.webp';
function ClientSlider() {
   
  return <>
  <Container>
      <Row>
          <Col>
          
            <h1>Worldwide Sourcing</h1>
          <marquee scrollamount="12" direction="left">
            <div class="slide"><img src={slidemenu} alt="slider" draggable="false"/></div>
            </marquee>
         
         
          </Col>
      </Row>
  </Container>
  

  </>;
}

export default ClientSlider;
