import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
function Error() {
    return (
        <>
           <Container>
               <Row>
                   <Col>
                      <div id="errorpage">
                      <i className="fas fa-bomb" ></i>
                      <p>Page not found!</p>
                      </div>
                        
                   </Col>
               </Row>
           </Container>
        </>
    )
}

export default Error
