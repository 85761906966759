import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import {Helmet} from "react-helmet";
function Productspecification(prop) {
  return (
    <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>{prop.name}| Alpha Refrigeration Company </title>
                
            </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <h1>{prop.name.toUpperCase()}</h1>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div>
              <p>{prop.specification}</p>
            </div>
            <div>{prop.product}</div>
          </Col>
          <Col lg={6}>
            <center>
              <img src={prop.imagepath} alt={prop.name} className="pic" />
              </center>
            
          </Col>
        </Row>
        <Row>
          <div>{prop.extra}</div>
        </Row>
      </Container>
    </>
  );
}

export default Productspecification;
