import React from "react";
import { Row, Container, Col } from "react-bootstrap";
function Header() {
  return (
    <>
      <Container fluid>
       
        <Row className="bg">
       
          <Col id="fav"><i class="fas fa-mobile-alt"></i>&nbsp;<a href='tel:+919822677479' className="headercall"> 9822677479</a> | <i class="fas fa-mobile-alt"></i>&nbsp;  <a href='tel:+918767022970' className="headercall">  8767022970</a> &nbsp;&nbsp;</Col>
        </Row>
      </Container>
    </>
  );
}

export default Header;
