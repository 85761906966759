import React from 'react';

//import { Container, Row, Col } from "react-bootstrap";

function Analytics() {
  return <>
  
        <div id="counter">
     
        <div className="row count-to-sec">
          <div className="col-lg-3 col-md-6 col-xs-12 count-one">
            <span className="icon"><i className="fa fa-cubes"> </i></span>
            <h3 className="timer count-value" data-to="800" data-speed="1000">800</h3>
            <hr className="width25-divider"/>
            <small className="count-title">Product Delivered</small>
          </div>
          <div className="col-lg-3 col-md-6 col-xs-12 count-one">
            <span className="icon"><i className="fa fa-user"> </i></span>
            <h3 className="timer count-value" data-to="700" data-speed="1000">700</h3>
            <hr className="width25-divider"/>
            <small className="count-title">Clients</small>
          </div>
          <div className="col-lg-3 col-md-6 col-xs-12 count-one">
            <span className="icon"><i className="fa fa-hourglass"> </i></span>
            <h3 className="timer count-value" data-to="22" data-speed="1000">22</h3>
            <hr className="width25-divider"/>
            <small className="count-title">Years work experience</small>
          </div>
          <div className="col-lg-3 col-md-6 col-xs-12 count-one">
            <span className="icon"><i className="fa fa-trophy"> </i></span>
            <h3 className="timer count-value" data-to="1" data-speed="1000">1</h3>
            <hr className="width25-divider"/>
            <small className="count-title">Award</small>
          </div>
        </div>
      </div>
    
        
  
  </>;
}

export default Analytics;
