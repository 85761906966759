import React from "react";
import Card from "react-bootstrap/Card";

import { Container, Row, Col } from "react-bootstrap";

function Tabs() {
    return ( <>
        <Container id = "home_tab" >
        <Row className = "justify-content-md-center" >
        <Row >
        <Col >
        <h1 id = "brand-slogan"
        className = "animate__animated animate__zoomInUp" >
        Turnkey solutions
        for Cold storages & Display Refrigeration

        </h1> </Col> </Row> <Col lg = "6" >
        <Card className = "zoom" >
        <Card.Body >
        <Card.Text >
        <p>
        Alpha refrigeration company is a leading manufacturer of cold storages, and evaporator indoor units. We are a one stop solutions provider who understand your project needs and offers you a comprehensive solutions to meet your requirements

        </p> </Card.Text> </Card.Body> </Card> </Col> <Col lg = "6" >
        <Card className = "zoom" >
        <Card.Body >
        <Card.Text >
        <p >
        In Alpha refrigeration company our key focus on to providing a complete turnkey solutions to our clients in project and retail segments in Cold Storages. As well as we provide professional food service solutions to a wide segment of industries such as hotels, hospitality, dairy industry, restaurants, food courts,
        fruits and vegetable industry, pharma and other sectors

        </p> </Card.Text> </Card.Body> </Card> </Col> </Row> <Row >
       </Row>

        </Container> 
        </>
    );
}

export default Tabs;